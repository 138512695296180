import React, { useCallback, useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useGlobalMouseMove } from "../../hooks/useWindowEvents";
import {
  motion,
  useMotionValue,
  useSpring,
  AnimatePresence,
} from "framer-motion";

const ArticleHeader = ({
  articleOpened,
  story = false,
  storyIsLoading = false,
  history = false,
}) => {
  /* VIEWMODE + SETTINGS */
  let viewMode = "cijferIndex";
  if (story) {
    viewMode = "story";
  }
  const strokeWidth = viewMode === "cijferIndex" ? 0.7 : 0.5;

  /* LOAD REDUX STATE */
  const { article, loading: articleIsLoading } = useSelector((state) => {
    return state.article;
  });
  const { categories, loading: categoriesAreLoading } = useSelector((state) => {
    return state.categories;
  });

  /* DEFINE SVG ELEMENTS */
  const circleCenters = {
    leftTop: { x: 18.6, y: 41.8 },
    rightBottom: { x: 134.4, y: 114.8 },
    centerCircle: { x: 79.5, y: 63.9 },
    rightTop: { x: 139.5, y: 39.4, r: 15.1 },
  };

  const circleSpringConfigs = {
    leftTop: { stiffness: 100 },
    rightBottom: { stiffness: 100, damping: 50 },
    letter: { stiffness: 20, damping: 20 },
  };
  const motionValues = {
    leftTop: {
      center: {
        x: useMotionValue(circleCenters.leftTop.x),
        y: useMotionValue(circleCenters.leftTop.y),
      },
      deviation: {
        x: useMotionValue(0),
        y: useMotionValue(0),
      },
    },
    rightBottom: {
      center: {
        x: useMotionValue(circleCenters.rightBottom.x),
        y: useMotionValue(circleCenters.rightBottom.y),
      },
      deviation: {
        x: useMotionValue(0),
        y: useMotionValue(0),
      },
    },
    letter: {
      deviation: {
        x: useMotionValue(0),
        y: useMotionValue(0),
      },
    },
  };

  const springValues = {
    leftTop: {
      center: {
        x: useSpring(
          motionValues.leftTop.center.x,
          circleSpringConfigs.leftTop
        ),
        y: useSpring(
          motionValues.leftTop.center.y,
          circleSpringConfigs.leftTop
        ),
      },
      deviation: {
        x: useSpring(
          motionValues.leftTop.deviation.x,
          circleSpringConfigs.leftTop
        ),
        y: useSpring(
          motionValues.leftTop.deviation.y,
          circleSpringConfigs.leftTop
        ),
      },
    },
    rightBottom: {
      center: {
        x: useSpring(
          motionValues.rightBottom.center.x,
          circleSpringConfigs.rightBottom
        ),
        y: useSpring(
          motionValues.rightBottom.center.y,
          circleSpringConfigs.rightBottom
        ),
      },
      deviation: {
        x: useSpring(
          motionValues.rightBottom.deviation.x,
          circleSpringConfigs.rightBottom
        ),
        y: useSpring(
          motionValues.rightBottom.deviation.y,
          circleSpringConfigs.rightBottom
        ),
      },
    },
    letter: {
      deviation: {
        x: useSpring(
          motionValues.letter.deviation.x,
          circleSpringConfigs.letter
        ),
        y: useSpring(
          motionValues.letter.deviation.y,
          circleSpringConfigs.letter
        ),
      },
    },
  };

  const updateMotionVars = useCallback((e) => {
    const percentX = (e.clientX / window.innerWidth) * 50;
    const percentY = (e.clientY / window.innerHeight) * 50;

    // leftTop
    let translateTransformFactor = 0.05;
    motionValues.leftTop.deviation.x.set(percentX * translateTransformFactor);
    motionValues.leftTop.deviation.y.set(percentY * translateTransformFactor);
    motionValues.leftTop.center.x.set(
      circleCenters.leftTop.x + percentX * translateTransformFactor
    );
    motionValues.leftTop.center.y.set(
      circleCenters.leftTop.y + percentY * translateTransformFactor
    );

    // rightBottom
    translateTransformFactor = -0.2;
    motionValues.rightBottom.deviation.x.set(
      percentX * translateTransformFactor
    );
    motionValues.rightBottom.deviation.y.set(
      percentY * translateTransformFactor
    );
    motionValues.rightBottom.center.x.set(
      circleCenters.rightBottom.x + percentX * translateTransformFactor
    );
    motionValues.rightBottom.center.y.set(
      circleCenters.rightBottom.y + percentY * translateTransformFactor
    );

    // letter
    translateTransformFactor = -0.1;
    motionValues.letter.deviation.x.set(percentX * translateTransformFactor);
    motionValues.letter.deviation.y.set(percentY * translateTransformFactor);
  }, []);
  useGlobalMouseMove(updateMotionVars);

  /* FRAMER MOTION */
  const subCircleVariants = {
    initial: { scale: 0, opacity: 0 },
    animate: { scale: 1, opacity: 1 },
    exit: { scale: 0, opacity: 0 },
    transition: { duration: 1 },
  };
  const subCircleTextVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0, transition: { delay: 0.2 } },
  };

  /* FILL SVG ELEMENTS */

  let showCircleLinksboven = false;
  let showCircleRechtsboven = false;
  let showCircleRechtsonder = false;
  let keyRechtsOnder = "";
  let keyLinksBoven = "";
  let keyRechtsBoven = "";

  let imageCenter = "";
  let imageLinksBoven = "";
  let imageRechtsBoven = "";
  let imageRechtsOnder = "";

  let mainColor = "#fff";
  let articleSlug = "";

  if (viewMode === "cijferIndex") {
    const breadcrumbTerms = history.location.pathname.split("/");
    if (breadcrumbTerms.length && breadcrumbTerms[0] === "") {
      breadcrumbTerms.splice(0, 1); // eerst is leeg ivm prefix slash
    }

    if (breadcrumbTerms[breadcrumbTerms.length - 1] === "") {
      breadcrumbTerms.splice(-1, 1); // laatste is leeg ivm trailing slash
    }
    if (
      !breadcrumbTerms.length ||
      (breadcrumbTerms.length && breadcrumbTerms[0] !== "inhoud")
    ) {
      return <></>;
    }
    articleSlug = breadcrumbTerms[breadcrumbTerms.length - 1];
    imageCenter =
      !categoriesAreLoading && articleSlug && categories[articleSlug].image
        ? categories[articleSlug].image
        : "";

    showCircleLinksboven =
      !categoriesAreLoading && !articleIsLoading && categories[articleSlug];
    // && categories[articleSlug].type !== "commissie";
    showCircleRechtsboven = true;
    showCircleRechtsonder =
      !categoriesAreLoading &&
      categories[articleSlug] &&
      categories[articleSlug].parent;

    if (showCircleRechtsboven) {
      keyRechtsBoven = articleSlug;
    }
    if (showCircleRechtsonder) {
      keyRechtsOnder = categories[articleSlug].parent;
      imageRechtsOnder = categories[categories[articleSlug].parent].image;
    }
  } else {
    if (storyIsLoading) {
      showCircleLinksboven = false;
      showCircleRechtsboven = false;
      showCircleRechtsonder = false;
    } else {
      if (story.header_settings.afbeelding_links_boven) {
        showCircleLinksboven = true;
        keyLinksBoven = "keyLinksBoven";
        imageLinksBoven = story.header_settings.afbeelding_links_boven;
      }
      if (story.header_settings.afbeelding_rechts_boven) {
        showCircleRechtsboven = true;
        keyRechtsBoven = "keyRechtsBoven";
        imageRechtsBoven = story.header_settings.afbeelding_rechts_boven;
      }
      if (story.header_settings.afbeelding_rechts_onder) {
        showCircleRechtsonder = true;
        keyRechtsOnder = "keyRechtsOnder";
        imageRechtsOnder = story.header_settings.afbeelding_rechts_onder;
      }
      if (story.header_settings.afbeelding_centraal) {
        imageCenter = story.header_settings.afbeelding_centraal;
      }
      switch (story.kleur) {
        case "green":
          mainColor = "#2c8980";
          break;
        default:
        case "orange":
          mainColor = "#f7935c";
          break;
        case "red":
          mainColor = "#f26854";
          break;
        case "yellow":
          mainColor = "#f39456";
          break;
        case "purple":
          mainColor = "#665e84";
          break;
      }
    }
  }

  return (
    <div className="articleHeader">
      <motion.svg
        style={{ originX: "100%", originY: "0%" }}
        initial="closed"
        animate={articleOpened ? "open" : "active"}
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 150 115"
      >
        <defs>
          {imageCenter !== "" && (
            <pattern
              id={`imageCenter-${articleSlug}`}
              patternUnits="userSpaceOnUse"
              width="150"
              height="115"
            >
              <image
                href={imageCenter}
                x="40.7"
                y="25.1"
                width="77.6"
                height="77.6"
              />
            </pattern>
          )}
          <clipPath id="center-cirkel-clippath">
            <circle cx="79.5" cy="63.9" r="38.85" />
          </clipPath>
          <pattern
            id="logoDGC"
            patternUnits="userSpaceOnUse"
            width="200"
            height="150"
          >
            <image
              href={`${process.env.PUBLIC_URL}/img/DGC.svg`}
              x={circleCenters.rightTop.x - circleCenters.rightTop.r}
              y={circleCenters.rightTop.y - circleCenters.rightTop.r}
              width={2 * circleCenters.rightTop.r}
              height={2 * circleCenters.rightTop.r}
            />
          </pattern>
          )}
          {showCircleRechtsonder && imageRechtsOnder && (
            <pattern
              id="imageRechtsOnder"
              patternUnits="userSpaceOnUse"
              width="200"
              height="150"
            >
              <image
                href={imageRechtsOnder}
                x="113.9"
                y="94.3"
                width="41"
                height="41"
              />
            </pattern>
          )}
          {showCircleRechtsboven && imageRechtsBoven && (
            <pattern
              id="imageRechtsBoven"
              patternUnits="userSpaceOnUse"
              width="200"
              height="150"
            >
              <image
                href={imageRechtsBoven}
                x="124.4"
                y="24.3"
                width="30.2"
                height="30.2"
              />
            </pattern>
          )}
          {imageLinksBoven && (
            <pattern
              id="imageLinksBoven"
              patternUnits="userSpaceOnUse"
              width="200"
              height="150"
            >
              <image
                href={imageLinksBoven}
                x="5.9"
                y="29.1"
                width="25.4"
                height="25.4"
              />
            </pattern>
          )}
        </defs>
        <g className="correction" transform="translate(-5.5, -23)">
          <g>
            <AnimatePresence exitBeforeEnter>
              {showCircleLinksboven && (
                <motion.g
                  id="Linksboven"
                  animate="animate"
                  initial="initial"
                  exit="exit"
                >
                  <motion.line
                    style={{
                      fill: "none",
                      stroke: "#E87647",
                      strokeWidth: strokeWidth,
                      x1: springValues.leftTop.center.x,
                      y1: springValues.leftTop.center.y,
                    }}
                    x2={circleCenters.centerCircle.x}
                    y2={circleCenters.centerCircle.y}
                  />

                  <motion.circle
                    key={`type-circle-${articleSlug}`}
                    variants={subCircleVariants}
                    style={{
                      fill:
                        viewMode === "story" ? "url(#imageLinksBoven)" : "#fff",
                      stroke: "#E87647",
                      strokeWidth: strokeWidth,
                      x: springValues.leftTop.deviation.x,
                      y: springValues.leftTop.deviation.y,
                    }}
                    cx="18.6"
                    cy="41.8"
                    r="12.7"
                  />
                  <motion.g
                    style={{
                      x: springValues.leftTop.deviation.x,
                      y: springValues.leftTop.deviation.y,
                    }}
                  >
                    <motion.text
                      variants={subCircleTextVariants}
                      textAnchor="middle"
                      x="18.5"
                      y="45"
                      style={{
                        fill: "#E87647",
                        fontFamily: "FFMarselisSlabWebProRegular",
                        fontSize: "10px",
                      }}
                    >
                      {/* {viewMode === "cijferIndex" &&
                        categories[articleSlug].type === "cluster" && <>C</>}
                      {viewMode === "cijferIndex" &&
                        categories[articleSlug].type === "jaarverslag" && (
                          <>J</>
                        )} */}
                      {viewMode === "cijferIndex" && <>2024</>}
                    </motion.text>
                  </motion.g>
                </motion.g>
              )}
            </AnimatePresence>

            <circle
              id="Center"
              style={{
                fill: "#fff",
                stroke: "#E87647",
                strokeWidth: strokeWidth,
              }}
              cx="79.5"
              cy="63.9"
              r={38.8 + strokeWidth / 2}
            />

            <AnimatePresence>
              {viewMode === "story" && (
                <motion.text
                  x="35"
                  y="100"
                  style={{
                    fill: mainColor,
                    fontFamily: "FFMarselisSlabWebProBold",
                    fontSize: "90px",
                    x: springValues.letter.deviation.x,
                    y: springValues.letter.deviation.y,
                  }}
                >
                  {!storyIsLoading && story && story.header_settings.letter}
                </motion.text>
              )}
            </AnimatePresence>

            <AnimatePresence exitBeforeEnter>
              {showCircleRechtsonder && (
                <motion.g
                  id="Rechtsonder"
                  animate="animate"
                  initial="initial"
                  exit="exit"
                >
                  <motion.line
                    style={{
                      fill: "none",
                      stroke: "#E87647",
                      strokeWidth: strokeWidth,
                      x1: springValues.rightBottom.center.x,
                      y1: springValues.rightBottom.center.y,
                    }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { delay: 0.2 } }}
                    exit={{
                      opacity: 0,
                      transition: { delay: 0 },
                    }}
                    x2={circleCenters.centerCircle.x}
                    y2={circleCenters.centerCircle.y}
                  />
                  <motion.circle
                    key={`circle-${keyRechtsOnder}`}
                    variants={subCircleVariants}
                    style={{
                      stroke: "#E87647",
                      strokeWidth: strokeWidth,
                      fill: "url(#imageRechtsOnder)",
                      x: springValues.rightBottom.deviation.x,
                      y: springValues.rightBottom.deviation.y,
                    }}
                    cx="134.4"
                    cy="114.8"
                    r="20.5"
                  />
                </motion.g>
              )}
            </AnimatePresence>

            <circle
              style={{
                fill: "#fff",
              }}
              cx="79.5"
              cy="63.9"
              r="38.8"
            />
            <AnimatePresence>
              <motion.circle
                key={`centerCircle-${articleSlug}`}
                fill={imageCenter ? `url(#imageCenter-${articleSlug})` : "none"}
                style={{
                  strokeWidth: 0,
                }}
                initial={{ opacity: 0, rotate: -2 }}
                animate={{
                  opacity: 1,
                  rotate: 0,
                  transition: { duration: 0.5 },
                }}
                exit={{
                  opacity: 0,
                }}
                cx="79.5"
                cy="63.9"
                r="38.8"
              />
            </AnimatePresence>

            {/*  RECHTSBOVEN */}
            {viewMode === "cijferIndex" && (
              <g key={`group-${keyRechtsBoven}`} id="Rechtsboven">
                <g>
                  <circle
                    variants={subCircleVariants}
                    key={`circle-${keyRechtsBoven}`}
                    style={{
                      fill:
                        viewMode === "story"
                          ? "url(#imageRechtsBoven)"
                          : "url(#logoDGC)",
                      stroke: "#E87647",
                      strokeWidth: strokeWidth,
                    }}
                    cx="139.5"
                    cy="39.4"
                    r="15.1"
                  />

                  <line
                    style={{
                      fill: "none",
                      stroke: "#E87647",
                      strokeWidth: strokeWidth,
                    }}
                    x1="125.2"
                    y1="44.9"
                    x2="115.3"
                    y2="48.8"
                  />
                </g>
              </g>
            )}

            {viewMode === "story" && (
              <AnimatePresence exitBeforeEnter>
                {showCircleRechtsboven && (
                  <motion.g
                    key={`group-${keyRechtsBoven}`}
                    id="Rechtsboven"
                    animate="animate"
                    initial="initial"
                    exit="exit"
                  >
                    <g>
                      <motion.circle
                        variants={subCircleVariants}
                        key={`circle-${keyRechtsBoven}`}
                        style={{
                          fill:
                            viewMode === "story"
                              ? "url(#imageRechtsBoven)"
                              : "url(#logoDGC)",
                          stroke: "#E87647",
                          strokeWidth: strokeWidth,
                        }}
                        cx="139.5"
                        cy="39.4"
                        r="15.1"
                      />

                      {viewMode === "story" && (
                        <motion.circle
                          variants={subCircleVariants}
                          key={`overlay-circle-${keyRechtsOnder}`}
                          style={{
                            fill: mainColor,
                            opacity: 0.3,
                            // mixBlendMode: "multiply",
                          }}
                          cx="139.5"
                          cy="39.4"
                          r="15.1"
                        />
                      )}

                      <line
                        style={{
                          fill: "none",
                          stroke: "#E87647",
                          strokeWidth: strokeWidth,
                        }}
                        x1="125.2"
                        y1="44.9"
                        x2="115.3"
                        y2="48.8"
                      />
                    </g>
                  </motion.g>
                )}
              </AnimatePresence>
            )}
            <AnimatePresence>
              {viewMode === "story" && (
                <motion.text
                  x="35"
                  y="100"
                  // clipPath="url(#center-cirkel-clippath)"
                  style={{
                    fill: mainColor,
                    fontFamily: "FFMarselisSlabWebProBold",
                    fontSize: "90px",
                    x: springValues.letter.deviation.x,
                    y: springValues.letter.deviation.y,

                    // mixBlendMode: "multiply",
                  }}
                >
                  {!storyIsLoading && story && story.header_settings.letter}
                </motion.text>
              )}
            </AnimatePresence>
          </g>
          {/* <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            style={{ fill: "url(#logoDGC)" }}
          ></rect> */}
        </g>
      </motion.svg>
    </div>
  );
};

export default ArticleHeader;
