import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  fetchCategoriesAndTags,
  unloadArticle,
  setSearchViewVisible,
  searchLookup,
} from "./redux/actions";
import Layout from "./components/layout.jsx";
import { Switch, Route, useHistory, Link } from "react-router-dom";
import CijferIndex from "./components/cijferIndex";
import Verhaal from "./components/verhaal";
import Home from "./pages/home";
import HomeExtra from "./components/extraIntroduction";
import { AnimatePresence, motion } from "framer-motion";
import Search from "./pages/search";
import Shortcuts from "./components/shortcuts";
import "./App.scss";
import { sendGoogleAnalyticsPageView } from "./helpers/helpers";
import Page from "./pages/page";
import VoorwoordVideo from "./pages/voorwoordVideo";
import CookieConsent, { Cookies } from "react-cookie-consent";

function App() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [cookieValue, setCookieValue] = useState();
  useEffect(() => {
    dispatch(fetchCategoriesAndTags());

    history.listen((location, action) => {
      dispatch(searchLookup(""));

      dispatch(setSearchViewVisible(false));
      dispatch(unloadArticle());
      window.scrollTo(0, 0);
    });
    setCookieValue(Cookies.get("cookieconsent_status"));
  }, []);

  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <div
      className={`jaarverslag2024App ${
        history.location.search && history.location.search === "?pdf=1"
          ? "pdf"
          : ""
      } ${cookieValue === undefined ? "showCookieOverlay " : ""}`}
    >
      <Layout history={history}>
        {/*  GOOGLE ANALYTICS */}
        <Route
          path="/"
          render={({ location }) => {
            if (window.ga) {
              sendGoogleAnalyticsPageView(
                `${location.pathname}${location.search}`
              );
            }

            return null;
          }}
        />
        <Route
          render={({ location }) => {
            return (
              <AnimatePresence exitBeforeEnter>
                <Switch location={location} key={location.pathname}>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/inhoud" component={Shortcuts} />
                  <Route
                    path={[
                      "/inhoud/:terms([a-z-/]+)?/:slug([a-z-/]+)?-:id(\\d+)",
                      "/inhoud/:terms([a-z-/]+)?",
                    ]}
                    component={CijferIndex}
                  />
                  <Route
                    path={["/achtergrond/:terms([a-z-/]+)?"]}
                    component={Verhaal}
                  />
                  {/* <Route
                    path={[
                      "/jacquelineberkelaar2020jaarverslagdegeschillencommissie",
                    ]}
                    component={VoorwoordVideo}
                  /> */}
                  <Route path={["/page/:slug"]} component={Page} />
                  <Route path={[":terms([a-z-/]+)?"]} component={Home} />
                </Switch>
              </AnimatePresence>
            );
          }}
        />
      </Layout>

      {history.location.pathname !== "/page/cookies" ? (
        <div className="cookieconsentOverlay">
          <CookieConsent
            flipButtons={true}
            cookieName="cookieconsent_status"
            buttonText="Accepteer cookies"
            cookieValue="allow"
            declineCookieValue="deny"
            enableDeclineButton={true}
            declineButtonText="Geen cookies"
            debug={true}
            onAccept={() => reloadPage()}
            onDecline={() => reloadPage()}
          >
            De Geschillencommissie maakt gebruik van cookies
            <span>
              Wij gebruiken functionele cookies, analytische cookies en cookies
              voor marketingdoeleinden.
            </span>
            <span>
              Door op 'Accepteer cookies' te klikken, gaat u akkoord met het
              gebruik van alle cookies zoals genoemd in{" "}
              <Link to="/page/cookies">ons beleid</Link>.
            </span>
          </CookieConsent>
        </div>
      ) : null}
    </div>
  );
}

export default App;
