import React, { useState, useRef } from "react";
import PrerequisiteSVG from "./svgComponents/prerequisiteSVG";
import PrerequisitesFacesSVG from "./svgComponents/prerequisitesFacesSVG";
import { motion, useCycle, AnimatePresence } from "framer-motion";
import LazyLoadBackgroundImage from "./lazyloadBackgroundImage";
import Button from "./button";
import ArticleContent from "./articleContent";
import { animateScroll } from "react-scroll";
import { sendGoogleAnalyticsEvent } from "../helpers/helpers";

const PrerequisitePeople = ({ homepageData, sectionInView }) => {
  const [active, setActive] = useState(1);
  const prerequisitesFacesSVGElement = useRef();

  const onClick = (e) => {
    animateScroll.scrollTo(
      prerequisitesFacesSVGElement.current.offsetParent?.offsetTop -
        window.innerWidth * 0.2
    );
    switch (e) {
      case 1:
      case 2:
      case 3:
        console.log(homepageData.voorwoorden[e - 1].titel);
        sendGoogleAnalyticsEvent(
          "Open Voorwoord",
          "Click",
          homepageData.voorwoorden[e - 1].titel
        );

        break;
      default:
        break;
    }
    setActive(e);
  };
  return (
    <div ref={prerequisitesFacesSVGElement}>
      <PrerequisitesFacesSVG
        active={active}
        setActive={onClick}
        sectionInView={sectionInView}
        images={[
          homepageData.voorwoorden[0].afbeelding,
          // homepageData.voorwoorden[1].afbeelding,
          // homepageData.voorwoorden[2].afbeelding,
        ]}
        hasVideo={[
          homepageData.voorwoorden[0].play_button_weergeven,
          // homepageData.voorwoorden[1].play_button_weergeven,
          // homepageData.voorwoorden[2].play_button_weergeven,
        ]}
      />

      {active === 0 ? (
        <div className="columns ">
          <div className="teaser">
            <LazyLoadBackgroundImage
              src={homepageData.voorwoorden[0].afbeelding}
              useClassName="image"
            />

            <div className="intro">
              <div>
                <h2>
                  {homepageData.voorwoorden[0].titel}
                  <span>{homepageData.voorwoorden[0].ondertitel}</span>
                </h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: homepageData.voorwoorden[0].introductie,
                  }}
                />
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Sequi, aliquam. Ratione tenetur maxime eum reprehenderit quam
                  ipsam optio architecto in nisi, velit neque aspernatur commodi
                  quidem ex aut illum et.
                </p>

                <Button
                  callBackFunction={() => {
                    onClick(1);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {homepageData.voorwoorden.map((voorwoord, index) => {
        if (active === index + 1) {
          return (
            <div
              className="columns prerequisiteOpen"
              key={`voorwoord-open-index`}
            >
              <div
                className="close"
                onClick={() => {
                  onClick(0);
                }}
              >
                sluiten
              </div>
              <div key={`voorwoord-column-${index}`} className="teaser">
                <LazyLoadBackgroundImage
                  src={voorwoord.afbeelding}
                  useClassName="image"
                />

                <div className="intro">
                  <div>
                    <h2>
                      {voorwoord.titel}
                      <span>{voorwoord.ondertitel}</span>
                    </h2>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: voorwoord.introductie,
                      }}
                    />
                  </div>
                </div>
              </div>
              <motion.div
                key={`voorwoord-${index}`}
                variants={{
                  animate: {
                    transition: {
                      staggerChildren: 0.2,
                      delayChildren: 0,
                    },
                  },
                }}
                initial="initial"
                animate="animate"
              >
                <ArticleContent
                  content={homepageData.voorwoorden[index].tekst_layout}
                />
              </motion.div>
            </div>
          );
        }
        return false;
      })}
    </div>
  );
};

export default PrerequisitePeople;
