import React, { useEffect } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Faq from "../components/faq";
import ArchiveLinks from "../components/archiveLinks";
import Prerequisite2024 from "../components/prerequisite2024";
import { motion, AnimatePresence, useViewportScroll } from "framer-motion";
import HomepageSection from "../components/homepageSection";
import StoryOverviewHome from "../components/storyOverviewHome";
import ExtraIntroduction from "../components/extraIntroduction";
import { fetchHomepageData } from "../redux/actions";
import BrandExplainer from "../components/brandExplainer";
import JaarverslagenIndexOverview from "../components/jaarverslagenIndexOverview";
import PrerequisitePeople from "../components/prerequisitePeople";

import dummydata from "./../dummy-homepagedata.json";
function Home(props) {
  /* LOAD HOMEPAGE DATA */
  const dispatch = useDispatch();
  const { scrollY } = useViewportScroll();
  // const logoOpacity = useTransform(scrollY, [0, 500], [1, 0]);

  useEffect(() => {
    dispatch(fetchHomepageData());
  }, []);

  const { homepageData, loading: homepageDataIsLoading } = useSelector(
    (state) => {
      return state.homepageData;
    }
  );
  // const homepageDataIsLoading = false;
  // const homepageData = dummydata;

  const match = useRouteMatch();
  let terms = [];
  if (match.params.terms) {
    terms = match.params.terms.split("/"); //substr haalt eerste slash weg
    if (terms.length && terms[terms.length - 1] == "") {
      terms.splice(-1, 1); // laatste is leeg ivm trailing slash
    }
  }
  let showExtraIntroduction = false;
  if (terms.length > 0) {
    showExtraIntroduction = true;
  }

  return (
    <motion.div exit={{ opacity: 0 }} className="home">
      {homepageDataIsLoading ? (
        <AnimatePresence exitBeforeEnter>
          {homepageDataIsLoading ? (
            <motion.div
              exit={{ opacity: 0 }}
              className="loadingIcon"
              key="loader"
            >
              <img src="./img/loading.svg" alt="" />
            </motion.div>
          ) : null}
        </AnimatePresence>
      ) : (
        <>
          <section className="intro2024">
            <div className="introwrapper">
              {/* <motion.div style={{ opacity: logoOpacity }}> */}
              <div className="logo">
                <h1
                // initial={{ opacity: 0 }}
                // animate={{ opacity: 1 }}
                // transition={{ duration: 0.5, delay: 0.2 }}
                >
                  samen
                  <br />
                  werken aan
                  <br />
                  kwaliteit
                </h1>
                <img
                  // initial={{ opacity: 0 }}
                  // animate={{ opacity: 1 }}
                  // transition={{ duration: 0.5, delay: 0.3 }}
                  alt="Logo De Geschillencommissie Jaarverslagen 2024"
                  src="./img/jaarverslagen2024.svg"
                />
              </div>
              <div className="backgroundImage">
                <img
                  // initial={{ opacity: 0 }}
                  // animate={{ opacity: 1 }}
                  // transition={{ duration: 0.6, delay: 0.4 }}
                  src="./img/headeranimatie--JV24.gif"
                  alt="Logo De Geschillencommissie Jaarverslagen 2024"
                />
              </div>
              {/* </motion.div> */}
            </div>
          </section>

          <HomepageSection cls="prerequisite " showOnLoad={true}>
            <Prerequisite2024 scrollY={scrollY} homepageData={homepageData} />
          </HomepageSection>

          <HomepageSection cls="prerequisites">
            <PrerequisitePeople homepageData={homepageData} />
          </HomepageSection>
          <HomepageSection cls="jaarverslagen">
            <JaarverslagenIndexOverview
              homepageData={homepageData}
              homepageDataIsLoading={homepageDataIsLoading}
            />
          </HomepageSection>

          <HomepageSection cls="storyOverview">
            <StoryOverviewHome homepageData={homepageData} />
          </HomepageSection>
          <HomepageSection cls="brandExplainer">
            <BrandExplainer scrollY={scrollY} homepageData={homepageData} />
          </HomepageSection>
          <HomepageSection cls="QnA">
            <Faq title="Onze basis en meer..." />
          </HomepageSection>
          <HomepageSection cls="earlierYears orange">
            <ArchiveLinks homepageData={homepageData} />
          </HomepageSection>
        </>
      )}
    </motion.div>
  );
}

export default Home;
