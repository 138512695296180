import React from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import Button from "./button";
import { sendGoogleAnalyticsEvent } from "../helpers/helpers";

const ShareArticle = ({ slug }) => {
  const location = useLocation();
  return (
    <motion.div
      className="shareAndDownload"
      initial={{ opacity: 0, y: "100%" }}
      animate={{ opacity: 1, y: "0%", transition: { delay: 1 } }}
    >
      <div className="share">
        {/* <strong>
          <span>deel dit artikel</span>
        </strong> */}
        <Button
          type="solid"
          text="Download PDF"
          icon="download"
          callBackFunction={() => {
            sendGoogleAnalyticsEvent("Download PDF", "click", slug);
            window.location.href = `https://www.samenwerkenaankwaliteit.nl/backend-jaarverslag-2024/?r=pdf&slug=${slug}`;
          }}
        />

        <div className="line"></div>
        <div className="shareLinks">
          <span
            onClick={() => {
              sendGoogleAnalyticsEvent("Share", "Facebook", slug);

              window.open(
                `https://www.facebook.com/sharer/sharer.php?u=https://www.samenwerkenaankwaliteit.nl/jaarverslag-2024${location.pathname}`
              );
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/img/icons/facebook-f-brands.svg`}
              alt="facebook"
            />
          </span>
          <a
            onClick={() => {
              sendGoogleAnalyticsEvent("Share", "Linked-in", slug);
            }}
            href={`https://www.linkedin.com/shareArticle?mini=true&url=https://www.samenwerkenaankwaliteit.nl/jaarverslag-2024${location.pathname}`}
            target="_blank"
          >
            <img
              src={`${process.env.PUBLIC_URL}/img/icons/linkedin-in-brands.svg`}
              alt="linkedin"
            />
          </a>
          {/* <Link to="/">
            <img src={`${process.env.PUBLIC_URL}/img/icons/twitter-brands.svg`} alt="twitter" />
          </Link> */}
          <a
            onClick={() => {
              sendGoogleAnalyticsEvent("Share", "E-mail", slug);
            }}
            href={`mailto:?body=https://www.samenwerkenaankwaliteit.nl/jaarverslag-2024${location.pathname}&subject=samenwerkenaankwaliteit.nl`}
          >
            <img
              src={`${process.env.PUBLIC_URL}/img/icons/envelope-solid.svg`}
              alt="mail"
            />
          </a>
        </div>
      </div>
    </motion.div>
  );
};

export default ShareArticle;
