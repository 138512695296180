import React from "react";
import { Link } from "react-router-dom";
const SearchSuggestions = (props) => {
  if (props.isSearching || props.searchSuggestions.length < 1) {
    // the system is searching with the query OR there are no SUGGESTIONS
    return false;
  }
  if (
    props.searchSuggestions.length == 1 &&
    props.searchSuggestions[0].name == props.searchQuery
  ) {
    // suggestion equal to search query
    return false;
  }

  return (
    <div className="searchSuggestions">
      Bent u wellicht geïnteresseerd in een van de volgende zoektermen?
      <br />
      <ul>
        {props.searchSuggestions.map((suggestion, index) => {
          let link = "";
          return (
            <li
              key={suggestion.nameSave + suggestion.id}
              onClick={() => props.fillSearchInput(suggestion.name)}
            >
              <span>{suggestion.name}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default SearchSuggestions;
