import React, { useEffect, useContext, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchArticle } from "../redux/actions";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import ArticleHeader from "./svgComponents/articleHeader";
import qs from "qs";
import { motion } from "framer-motion";
import Accordion from "./accordion";
import ArticleContent from "./articleContent";
import Button from "./button";
import ShareArticle from "./shareArticle";
import ThumbnailStory from "./thumbnailStory";
import ThumbnailTegel from "./thumbnailTegel";
import ArticleListSmall from "./articleListSmall";

function Article(props) {
  const {
    articleSlug,
    articleName,
    articleImage,
    children,
    setVisibiltySidebarFooter,
    parent,
    pathTerms = [],
    categories,
  } = props;

  const location = useLocation();
  const history = useHistory();
  const URLquery = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [articleOpenStatus, setArticleOpenStatus] = useState(
    typeof children === "undefined" || URLquery.pdf ? true : false
  );

  // setVisibiltySidebarFooter(articleOpenStatus);

  const { article, loading: articleIsLoading } = useSelector((state) => {
    return state.article;
  });

  const tegelType = categories[articleSlug].type;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchArticle(articleSlug));
    if (typeof children === "undefined") {
      setArticleOpenStatus(true);
    } else {
      if (!URLquery.pdf) {
        setArticleOpenStatus(false);
      }
    }
  }, [articleSlug]);

  return (
    <article
      className={`${articleOpenStatus ? "open" : "closed"} ${
        articleIsLoading ? "loading" : ""
      }`}
    >
      {URLquery.pdf && (
        <img
          src={`${process.env.PUBLIC_URL}/img/logo-mobile-header.svg`}
          alt="logo"
          className="logo"
        />
      )}
      <div className="header">
        <div className="heading">
          {/* <Breadcrumbs /> */}
          {/* {articleOpenStatus && (
            <>
              {parent && (
                <Link className="back" to={parent.link}>
                  {"<-"} terug naar {parent.name}
                </Link>
              )}
            </>
          )} */}
          <h1>
            {tegelType !== "jaarverslag" &&
            !articleName.toLowerCase().startsWith("tuchtcommissie") ? (
              <>
                <span className="type">{tegelType} </span>
                {articleName}
              </>
            ) : (
              articleName
            )}
          </h1>
          {!articleIsLoading && (
            <motion.h2 initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              {article.subtitel}
            </motion.h2>
          )}
          {articleIsLoading && <h2> </h2>}
        </div>
      </div>
      {!articleOpenStatus && (
        <>
          {!articleIsLoading && (
            <>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="introduction"
                dangerouslySetInnerHTML={{ __html: article.intro }}
              />
              <Button
                type="solid"
                text={`Lees verder over ${articleName}`}
                icon="arrow-down"
                callBackFunction={(e) => {
                  e.preventDefault();
                  // history.push({ pathname: location.pathname });
                  setArticleOpenStatus(!articleOpenStatus);
                }}
              />

              {/* <a
                className="readMore orange button icon-down-arrow"
               
                href={`${location.pathname}?open=true`}
              >
                Lees verder over {articleName}
              </a> */}
            </>
          )}
          {articleIsLoading && (
            <div className="introduction">
              <img className="loadingIcon" src="./img/loading.svg" alt="" />
            </div>
          )}
        </>
      )}
      {articleOpenStatus && articleIsLoading && (
        <div className="articleContentPlaceholder" />
      )}
      {articleOpenStatus && !articleIsLoading && (
        <motion.div
          className={`articleContent`}
          animate={
            articleOpenStatus
              ? { height: "auto", opacity: 1, pointerEvents: "all" }
              : { height: "200px", pointerEvents: "none" }
          }
        >
          <div
            className="introduction"
            dangerouslySetInnerHTML={{ __html: article.intro }}
          />
          {article.tekst_layout && (
            <motion.div
              initial="initial"
              animate="animate"
              variants={{
                initial: false,
                animate: { transition: { staggerChildren: 0.1 } },
              }}
            >
              <ArticleContent content={article.tekst_layout} />
            </motion.div>
          )}
          {article.automatisch_infographic_genereren && (
            <div className="infographic">
              <h2>
                {tegelType !== "jaarverslag" &&
                !articleName.toLowerCase().startsWith("tuchtcommissie") ? (
                  <>
                    {tegelType} {articleName}
                  </>
                ) : (
                  <>{articleName}</>
                )}
              </h2>
              <SVG
                cacheRequests={true}
                src={`https://www.samenwerkenaankwaliteit.nl/backend-jaarverslag-2024/?r=infographic&id=${article.id}&webSvg`}
              />
            </div>
          )}
          {!article.automatisch_infographic_genereren &&
            article.infographic_upload && (
              <img src={article.infographic_upload.url} alt="infographic" />
            )}
          {article.voorbeeld_uitspraken && (
            <div className="exampleJudgments">
              <h3>
                {article.voorbeeld_uitspraken.length > 1 &&
                  "Geselecteerde uitspraken"}
                {article.voorbeeld_uitspraken.length === 1 &&
                  "Geselecteerde uitspraak"}
              </h3>

              <Accordion items={article.voorbeeld_uitspraken} />
            </div>
          )}
        </motion.div>
      )}
      {articleOpenStatus && <ShareArticle slug={articleSlug} />}
      {articleOpenStatus &&
        article.gerelateerde_artikelen &&
        article.gerelateerde_artikelen.tegels.length +
          article.gerelateerde_artikelen.stories.length >
          0 && (
          <>
            <h4 className="underline see-also">
              <span>Bekijk ook</span>
            </h4>
            {!articleIsLoading && (
              <ArticleListSmall articles={article.gerelateerde_artikelen} />
            )}
          </>
        )}
    </article>
  );
}

export default Article;
